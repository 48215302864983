<template>
  <div class="discord">
    <h2>Get in touch with me on discord:</h2>
    <h1 class="center-main">
      <em>
        <u>Odayaka#1295</u>
      </em>
    </h1>
  </div>
</template>

<script>
export default {
  name: "dc"
};
</script>

<style>
.center-main {
  font-size: 5rem;
  text-align: center;
}
</style>